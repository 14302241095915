import { Search } from "lucide-react";
import { useState, useEffect } from "react";
import { Card, Typography, Box, Stack, useMediaQuery } from "@mui/material";
import TaskOutlined from "@mui/icons-material/TaskOutlined";
import { Skeleton } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import CustomAccordian from "../pages/CustomAccordian";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { axiosInstance } from "../api/axios";
import SecureNEX from "../assets/images/secureNEX.png";
import LowRiskMessage from "../pages/LowRiskMessage";
import NessusAccordion from "../pages/NessusAccordian";
import { SCAN_REPORT, SCAN_TRIGGER, SCANPDF } from "../api/config";
import CustomBarChart from "../pages/CustomBarChart";
import CustomPieChart from "../pages/CustomPieChart";
import FeatureCards from "../pages/FeatureCard";
import { setScanProgress } from "../redux/reducers/scanDataReducer";
import { useWebSocket } from "../hooks/useWebSocket"; // Import the custom hook

export const ScanComponent = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [searchTxt, setSearchTxt] = useState("");
  const [scannedDomain, setScannedDomain] = useState("");
  const [scanData, setScanData] = useState({
    scan_id: "",
    status: "Not Started",
  });
  const [scanReportData, setScanReportData] = useState([]);
  const [isScanTriggerComplete, setIsScanTriggerComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [highestSeverity, setHighestSeverity] = useState("loading");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [chartData, setChartData] = useState([
    { label: "Critical", value: 0, color: "#f17e92" },
    { label: "High", value: 0, color: "#fbd5da" },
    { label: "Medium", value: 0, color: "#bedcfe" },
    { label: "Low", value: 0, color: "#90c7fe" },
    { label: "Info", value: 0, color: "#3b86f7" },
  ]);

  const { uid } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Use the custom WebSocket hook
  const { message, connectionStatus } = useWebSocket(uid);

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (message) {
      // Update scan status
      if (message.status) {
        const formattedStatus = message.status.charAt(0).toUpperCase() + message.status.slice(1);
        setScanData((prev) => ({
          ...prev,
          status: formattedStatus,
        }));
      }

      // Update chart data
      if (message.severity_counts) {
        setChartData([
          { label: "Critical", value: message.severity_counts.critical || 0, color: "#f17e92" },
          { label: "High", value: message.severity_counts.high || 0, color: "#fbd5da" },
          { label: "Medium", value: message.severity_counts.medium || 0, color: "#bedcfe" },
          { label: "Low", value: message.severity_counts.low || 0, color: "#90c7fe" },
          { label: "Info", value: message.severity_counts.info || 0, color: "#3b86f7" },
        ]);
      }
    }
  }, [message]);

  const generatePdf = async () => {
    if (!scanData.scan_id) {
      toast.error("No scan ID available to generate PDF.");
      return;
    }

    try {
      setLoadingPdf(true);
      const response = await axiosInstance.post(
        SCANPDF,
        {
          file_type: "pdf",
          user_id: uid,
          scan_id: scanData.scan_id,
        },
        {
          responseType: "blob",
          headers: {
            Cookie: document.cookie,
          },
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `ScanReport_${scanData.scan_id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("PDF generated successfully!");
    } catch (error) {
      toast.error("Failed to generate PDF. Please try again.");
      console.error("Error generating PDF:", error.response?.data || error.message);
    } finally {
      setLoadingPdf(false);
    }
  };

  const triggerScan = async () => {
    try {
      setLoading(true);

      // Reset states for new scan
      setScanData({ scan_id: "", domain_name: "", status: "Not Started" });
      setScanReportData([]);
      setIsScanTriggerComplete(false);
      setInitialLoad(true);

      setScannedDomain(searchTxt);

      const postResponse = await axiosInstance.post(
        SCAN_TRIGGER,
        {
          domain_name: searchTxt,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (postResponse.data?.status === true) {
        setScanData({
          scan_id: postResponse.data.scan_id || "N/A",
          domain_name: searchTxt,
          status: "Scan Started",
        });
        setIsScanTriggerComplete(true);

        // Fetch the scan report after triggering the scan
        await GetScanReport(postResponse.data.scan_id);
      } else {
        setScanData({
          scan_id: "",
          domain_name: searchTxt,
          status: "Not Started",
        });
        setIsScanTriggerComplete(false);
      }
    } catch (error) {
      console.error("Error occurred while triggering scan:", error.response?.data || error.message);
      setScanData({
        scan_id: "",
        domain_name: searchTxt,
        status: "Not Started",
      });
      setIsScanTriggerComplete(false);
      toast.error("Failed to start scan. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const GetScanReport = async (scanId, page = 1, size = 100) => {
    try {
      setLoading(true);
      const postData = { scan_id: scanId, page, size };
      const response = await axiosInstance.post(SCAN_REPORT, postData);

      const scanData = response?.data?.data?.scan_data?.data || [];
      const severityCounts = response?.data?.data?.severity_counts;

      setScanReportData(scanData);

      try {
        const currentHighestSeverity = response?.data?.data?.risk_level;
        setHighestSeverity(currentHighestSeverity.toLowerCase());
        setChartData([
          { label: "Critical", value: severityCounts.critical, color: "#f17e92" },
          { label: "High", value: severityCounts.high, color: "#fbd5da" },
          { label: "Medium", value: severityCounts.medium, color: "#bedcfe" },
          { label: "Low", value: severityCounts.low, color: "#90c7fe" },
          { label: "Info", value: severityCounts.info, color: "#3b86f7" },
        ]);
      } catch (error) {
        console.error("Error setting severity:", error);
        setHighestSeverity("Info");
        setChartData([
          { label: "Critical", value: 0, color: "#f17e92" },
          { label: "High", value: 0, color: "#fbd5da" },
          { label: "Medium", value: 0, color: "#bedcfe" },
          { label: "Low", value: 0, color: "#90c7fe" },
          { label: "Info", value: 0, color: "#3b86f7" },
        ]);
      }

      // Update scan status based on response
      if (response.data?.data?.status === "Completed") {
        setScanData((prev) => ({ ...prev, status: "Completed" }));
      }

      setInitialLoad(false);
    } catch (error) {
      console.error("Error fetching report:", error);
      toast.error("Error fetching scan report.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* VAPT Scan Section */}
      <Box className="grid grid-cols-1 max-sm:gap-16 sm:gap-0 sm:grid-cols-[55%_45%] md:grid-cols-[60%_40%] lg:grid-cols-[70%_30%] bg-[#14212D] rounded-lg max-sm:py-6 sm:py-7 md:py-10 lg:py-14">
        {/* Left side */}
        <Box
          className="flex flex-col items-center text-center sm:items-start sm:text-left "
          sx={{
            paddingX: {
              xs: "10px",
            },
            paddingLeft: {
              sm: "32px",
              md: "40px",
              lg: "56px",
              xl: "80px",
            },
          }}
        >
          <Typography
            variant="h6"
            className="text-white mt-3"
            sx={{
              fontSize: {
                sm: "1.1rem",
                md: "1.15rem",
                lg: "1.25rem",
              },
            }}
          >
            Still worrying about
            <br /> vulnerabilities?
          </Typography>
          <Typography
            variant="body2"
            className="text-slate-300 italic mt-2"
            sx={{
              fontSize: {
                md: "1.05rem",
                lg: "1.15rem",
              },
            }}
          >
            Try performing an automated VAPT assessment
          </Typography>
          <Box className="flex flex-col md:flex-row gap-2 mt-4 items-center sm:items-start">
            <Box className="relative flex-1 max-w-md">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Enter Domain Name"
                className="max-sm:w-48 w-full p-1 rounded-lg bg-background pl-8"
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
              />
            </Box>
            <Button
              className="max-sm:w-48 sm:min-w-full md:min-w-24 bg-[#1B69AC] text-white"
              onClick={triggerScan}
            >
              Scan
            </Button>
          </Box>
        </Box>
        {/* Right side */}
        <Box className="flex items-center justify-center md:justify-end md:pr-6 lg:pr-14 xl:pr-16">
          <img
            src={SecureNEX}
            alt="Logo"
            className="h-36 w-auto filter brightness-125 max-sm:h-28 sm:h-32 lg:h-36"
          />
        </Box>
      </Box>

      <Box>
        {isScanTriggerComplete ? (
          loading && initialLoad ? (
            <Box sx={{ mt: 5 }}>
              <Stack spacing={1}>
                <Skeleton variant="text" width="40%" height={30} />
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="60%" height={20} />
                <Skeleton variant="rectangular" height={150} />
                <Skeleton variant="text" width="20%" height={30} />
              </Stack>
            </Box>
          ) : (
            <Box className="mt-5">
              <Box className="grid grid-cols-2 mt-0 relative overflow-hidden">
                <Typography
                  variant="h7"
                  sx={{
                    color: "Black",
                    fontStyle: "Bold",
                  }}
                >
                  Detailed scan report
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="min-w-24 bg-[#1B69AC] text-white"
                    style={{ zIndex: 2 }}
                    starticon={<PictureAsPdfIcon />}
                    onClick={generatePdf}
                    disabled={loadingPdf}
                  >
                    {loadingPdf ? "Generating..." : "Download PDF"}
                  </Button>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                {scanReportData && scanReportData.length > 0 ? (
                  <LowRiskMessage riskLevel={highestSeverity} />
                ) : (
                  <LowRiskMessage riskLevel="loading" />
                )}
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: isMobile ? "1fr" : "23% auto",
                  gap: 2,
                  mt: 2,
                  overflowX: "hidden", // Prevent horizontal scrolling
                }}
              >
                {/* Left Column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%", // Ensure it takes full width
                  }}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F8FAFD",
                      boxShadow: "none",
                      border: "none",
                      width: "100%", // Ensure it takes full width
                    }}
                  >
                    <Box className="grid grid-rows-2 gap-1 mt-5 mb-5">
                      <Box
                        className="grid grid-cols-2 mt-0"
                        sx={{ gridTemplateColumns: "20% auto" }}
                      >
                        <Box className="flex items-center justify-end">
                          <TaskOutlined
                            sx={{ color: "hsl(var(--muted-foreground))" }}
                          />
                        </Box>

                        <Box className="mt-5">
                          <Box className="px-8 mt-1 text-sm text-muted-foreground">
                            Scan details
                          </Box>
                          <Box className="px-8 text-xs mt-1 text-black">
                            Scan ID: {scanData.scan_id}
                          </Box>
                          <Box className="px-8 text-xs mt-0 text-black">
                            Domain Name: {scannedDomain}
                          </Box>
                          
                        </Box>
                      </Box>

                      <Box
                        className="grid grid-cols-2 mt-10"
                        sx={{ gridTemplateColumns: "20% auto" }}
                      >
                        <Box className="flex items-center justify-end">
                          <TaskOutlined
                            sx={{ color: "hsl(var(--muted-foreground))" }}
                          />
                        </Box>
                        <Box>
                          <Box className="px-8 mt-1 text-sm text-muted-foreground">
                            Status
                          </Box>
                          <Box
                            className="px-8 text-xs mt-0"
                            sx={{ color: "#3ED598" }}
                          >
                            {scanData.status}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>

                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F8FAFD",
                      boxShadow: "none",
                      border: "none",
                      width: "100%", // Ensure it takes full width
                    }}
                  >
                    <ResponsiveContainer width="100%" height={200}>
                      <CustomBarChart data={chartData} />
                    </ResponsiveContainer>
                  </Card>

                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F8FAFD",
                      boxShadow: "none",
                      border: "none",
                      width: "100%", // Ensure it takes full width
                    }}
                  >
                    <ResponsiveContainer width="100%" height={240}>
                      <CustomPieChart
                        data={chartData}
                        innerRadius={50}
                        outerRadius={80}
                      />
                    </ResponsiveContainer>
                  </Card>
                </Box>

                {/* Right Column */}
                <Box
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: "100%", // Ensure it takes full width
                  }}
                >
                  {scanReportData.map((report, index) => {
                    if (report && Object.keys(report).length > 0) {
                      return (
                        <Card
                          key={index}
                          variant="outlined"
                          sx={{
                            backgroundColor: "#FFFFF",
                            boxShadow: "none",
                            border: "none",
                            margin: 0,
                            padding: 0,
                            mb: 1,
                            width: "100%", // Ensure it takes full width
                          }}
                        >
                          {report.name === "VA Scan Results" ? (
                            <NessusAccordion
                              name={report.nessus_info.Synopsis}
                              nessusInfo={report.nessus_info}
                            />
                          ) : (
                            <CustomAccordian key={index} data={report} />
                          )}
                        </Card>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Box>
            </Box>
          )
        ) : (
          !loading && (
            <Box
              sx={{
                marginTop: {
                  sm: "140px",
                  lg: "80px",
                },
                transform: {
                  sm: "scale(1.25)",
                  lg: "scale(1.1)",
                },
                display: { xs: "none", sm: "block" },
              }}
            >
              <FeatureCards triggerScan={triggerScan} />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};