/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import { useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  password_change,
  Button,
  passwordChangeValidationSchema,
  CustomCard,
} from "./index";

const PasswordChangeForm = () => {
  const { uid } = useSelector((state) => state.auth);

  // Form submission handler
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        user_id: uid,
        old_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_new_password: values.confirmNewPassword,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + password_change,
        payload
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        resetForm();
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error || "Something went wrong.");
      } else {
        toast.error("Unable to connect to the server.");
      }
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <ToastContainer position="top-right" autoClose={2000} theme="dark" />
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={passwordChangeValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <CustomCard title="Password Reset">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                paddingX: {
                  lg: 3,
                  xl: 5,
                },
                marginTop: 1,
              }}
            >
              {/* Old Password Field */}
              <Grid item xxs={12}>
                <PasswordField
                  label="Enter Old Password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  error={touched.oldPassword && Boolean(errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                />
              </Grid>

              {/* New Password and Confirm New Password Fields */}
              <Grid container spacing={2}>
                {passwordFields.map((field, index) => (
                  <Grid
                    item
                    xxs={12}
                    sm={field.name === "oldPassword" ? 12 : 6} // Full width for oldPassword, half for the rest
                    key={index}
                  >
                    <PasswordField
                      label={field.label}
                      name={field.name}
                      value={values[field.name]}
                      onChange={handleChange}
                      error={touched[field.name] && Boolean(errors[field.name])}
                      helperText={touched[field.name] && errors[field.name]}
                    />
                  </Grid>
                ))}
              </Grid>

              {/* Submit Button */}
              <Grid
                item
                xxs={12}
                display="flex"
                justifyContent="flex-end"
                marginTop={2}
              >
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="w-full sm:w-52"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Grid>
            </Box>
          </CustomCard>
        )}
      </Formik>
    </Box>
  );
};

const passwordFields = [
  {
    label: "Enter New Password",
    name: "newPassword",
  },
  {
    label: "Confirm New Password",
    name: "confirmNewPassword",
  },
];

const PasswordField = ({ label, name, value, onChange, error, helperText }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((prev) => !prev)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordChangeForm;
