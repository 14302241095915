import { createSlice } from "@reduxjs/toolkit";

// Load state from localStorage
const loadStateFromLocalStorage = () => {
  try {
    const storedState = localStorage.getItem("authState");
    const parsedState = storedState ? JSON.parse(storedState) : null;

    return parsedState;
  } catch (error) {
    console.error("Failed to load state from localStorage:", error);
    return null;
  }
};

// Save state to localStorage
const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("authState", serializedState);
  } catch (error) {
    console.error("Failed to save state to localStorage:", error);
  }
};

// Clear state from localStorage
const clearStateFromLocalStorage = () => {
  try {
    localStorage.removeItem("authState");
  } catch (error) {
    console.error("Failed to clear state from localStorage:", error);
  }
};

// Initialize state (either from localStorage or default)
const initialState = loadStateFromLocalStorage() || {
  loggedIn: false,
  email: "",
  uid: "",
  isVerified: false,
  full_name: "",
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      if (!action.payload) {
        console.warn("setAuth received empty payload");
        return;
      }

      const { loggedIn, email } = action.payload;

      state.email = email || "";
      state.loggedIn = loggedIn;

      // Save updated state to localStorage
      saveStateToLocalStorage(state);
    },
    setIsVerified: (state, action) => {
      if (action.payload && typeof action.payload.isVerified === "boolean") {
        state.isVerified = action.payload.isVerified;
        saveStateToLocalStorage(state);
      }
    },
    setUID: (state, action) => {
      if (action.payload && typeof action.payload.uid === "string") {
        state.uid = action.payload.uid;
        saveStateToLocalStorage(state);
      }
    },
    setName: (state, action) => {
      if (action.payload && typeof action.payload.full_name === "string") {
        state.full_name = action.payload.full_name;
        saveStateToLocalStorage(state);
      }
    },
    clearUserData: (state) => {
      state.loggedIn = false;
      state.email = "";
      state.uid = "";
      state.isVerified = false;
      state.full_name = "";

      // Clear state from localStorage
      clearStateFromLocalStorage();
    },
  },
});

export const { setAuth, setIsVerified, setUID, setName, clearUserData } =
  authReducer.actions;
export default authReducer.reducer;
