import axios from 'axios';
import { APP_API_URL } from './config';

export const axiosUnAuth = axios.create({
    baseURL: APP_API_URL,
});

export const axiosInstance = axios.create({
    baseURL: APP_API_URL,
    withCredentials: true,
});

export const axiosPrivate = axios.create({
    baseURL: APP_API_URL,
    withCredentials: true,
});
