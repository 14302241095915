/* eslint-disable react/prop-types */
import React from "react";
import { BarChart, Bar, XAxis, YAxis, Cell } from "recharts";

const CustomBarChart = ({ data, width, height }) => {
  const severityColors = [
    { label: "Critical", color: "#f17e92" },
    { label: "High", color: "#fbd5da" },
    { label: "Medium", color: "#bedcfe" },
    { label: "Low", color: "#90c7fe" },
    { label: "Info", color: "#3b86f7" },
  ];

  // Function to get color based on severity
  const getColorForSeverity = (label) => {
    const colorItem = severityColors.find(
      (item) => item.label.toLowerCase() === label.toLowerCase()
    );
    return colorItem ? colorItem.color : "#000"; // Default to black if no match
  };

  return (
    <BarChart
      data={data}
      width={width} // Using width passed from parent component
      height={height} // Using height passed from parent component
      layout="horizontal"
      margin={{ top: 30, right: 25, bottom: 0, left: 0 }}
    >
      <XAxis
        dataKey="label"
        stroke="#888888"
        fontSize={8}
        tickLine={false}
        axisLine={false}
      />
      <YAxis
        stroke="#888888"
        fontSize={8}
        tickLine={false}
        axisLine={false}
        tickFormatter={(value) => `${value}`}
      />
      <Bar dataKey="value" radius={[2, 2, 0, 0]} barSize={35}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={getColorForSeverity(entry.label)} />
        ))}
      </Bar>
    </BarChart>
  );
};

export default CustomBarChart;
