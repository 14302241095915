export const registration = "/users/registration";
export const login = "/users/login";
//export const token = '/users/token/refresh';
export const token_verification = "/users/token/verification";
export const logout = "/users/logout";
export const profile = "/users/profile";
export const otp_send = "/users/otp";
export const email_verification = "/users/verification";
export const password_reset = "/users/password-reset";
export const password_reset_request = "/users/password-reset-request";
export const password_reset_verify = "/users/password-reset-verify";
export const password_change = "/users/password-change";
export const name_change = "/users/name-change";
