/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Stack,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Input,
  Button,
  VendorCard,
  CveCard,
  CVE_API,
  CVE_API_PATHS,
  LoadingImage,
} from "./index";

const CVE_Details = () => {
  const [isCveTriggerComplete, setIsCveTriggerComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("cve");
  const [cveId, setCveId] = useState("");
  const [vendor, setVendor] = useState("");
  const [version, setVersion] = useState("");
  const [response, setResponse] = useState(null);

  useEffect(() => {
    setCveId("");
    setVendor("");
    setVersion("");
    setResponse(null);
    setIsCveTriggerComplete(false);
  }, [searchType]);

  const triggerCveSearch = async () => {
    if (searchType === "cve") {
      if (!cveId.trim()) {
        toast.error("Please enter a CVE ID.");
        return;
      }
      const cvePattern = /^CVE-\d{4}-\d{4,7}$/;
      if (!cvePattern.test(cveId.trim())) {
        toast.error("Invalid CVE ID format. Expected format: CVE-YYYY-XXXXX.");
        return;
      }
    } else if (searchType === "vendor") {
      if (!vendor.trim()) {
        toast.error("Please enter a vendor name.");
        return;
      }
    }

    setLoading(true);
    setResponse(null);

    try {
      let apiUrl = CVE_API;
      if (searchType === "cve" && cveId.trim()) {
        apiUrl += `${CVE_API_PATHS.cveId}${cveId}`;
      } else if (searchType === "vendor" && vendor.trim()) {
        const queryParams = `?vendor=${vendor}${
          version.trim() ? `&version=${version}` : ""
        }`;
        apiUrl += `${CVE_API_PATHS.search}${queryParams}`;
      }

      const apiResponse = await fetch(apiUrl);
      if (!apiResponse.ok) {
        throw new Error("Failed to fetch CVE details.");
      }
      const data = await apiResponse.json();

      if (searchType === "vendor") {
        setResponse(Array.isArray(data) ? data : []);
      } else {
        setResponse(data);
      }

      setIsCveTriggerComplete(true);
    } catch (error) {
      toast.error("An error occurred while fetching CVE details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <ToastContainer position="top-right" autoClose={3000} theme="dark" />
      <Box
        sx={{
          backgroundColor: "#14212D",
          borderRadius: "16px",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "white", fontWeight: "bold", mb: 2 }}
        >
          Find vulnerabilities by CVE ID or Vendor
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            maxWidth: "800px",
            mb: 3,
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <FormControl
            sx={{
              width: "150px",
              backgroundColor: "transparent",
              border: "1px solid white",
              borderRadius: "4px",
            }}
          >
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              sx={{
                color: "white",
                backgroundColor: "transparent",
                height: "30px",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiSelect-icon": { color: "white" },
              }}
            >
              <MenuItem value="cve">CVE ID</MenuItem>
              <MenuItem value="vendor">Vendor</MenuItem>
            </Select>
          </FormControl>

          {searchType === "cve" ? (
            <Input
              type="text"
              placeholder="Enter CVE ID (e.g., CVE-2024-12345)..."
              value={cveId}
              onChange={(e) => setCveId(e.target.value)}
              style={{ flex: 1, backgroundColor: "white" }}
            />
          ) : (
            <Box sx={{ display: "flex", gap: "16px", flex: 1 }}>
              <Input
                type="text"
                placeholder="Enter Vendor..."
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                style={{ flex: 1, backgroundColor: "white" }}
              />
              <Input
                type="text"
                placeholder="Enter Version (Optional)"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                style={{ flex: 1, backgroundColor: "white" }}
              />
            </Box>
          )}
          <Button
            className="min-w-24 bg-[#1B69AC] text-white"
            onClick={triggerCveSearch}
            sx={{
              width: "150px",
              height: "35px",
            }}
          >
            Search
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        {!isCveTriggerComplete && !loading && (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "gray",
              fontStyle: "italic",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={LoadingImage} // Using the imported image
              alt="Loading illustration"
              style={{
                width: "1000px", // Set the width to a fixed size
                height: "auto", // Keep aspect ratio
                // Optionally, you can set maxHeight as well to constrain the size
                // Max height if needed, adjust as necessary
              }}
            />
          </Typography>
        )}
        {loading ? (
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={140} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Stack>
        ) : isCveTriggerComplete && response ? (
          searchType === "cve" ? (
            <CveCard cveDetails={response} />
          ) : Array.isArray(response) ? (
            response.map((item, index) => (
              <VendorCard key={index} vendorDetails={item} />
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No vendor results found.
            </Typography>
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default CVE_Details;
