/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./assets/css/custom.scss";
import store from "./redux/store";
import App from "./App";
import { TooltipProvider } from "./components/ui/tooltip";

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 320,
      xs: 480, // 320 - 480 Mobile Phones (Portrait)
      sm: 640,
      md: 768, // 768-1024 Tablets (Portrait)
      lg: 1024, //1024 - 1920 Desktops
      xl: 1280,
      xxl: 1536,
    },
  },
}); // You can customize the theme here if needed

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <TooltipProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </TooltipProvider>
    </BrowserRouter>
  </Provider>
);
