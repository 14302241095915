/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Card, CardContent } from "@mui/material";
import { styled } from "@mui/system";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

const CustomizedAccordion = styled(Accordion)(() => ({
  backgroundColor: "#F8FAFD",
  borderRadius: "2rem",
  boxShadow: "none",
  border: "1px solid rgba(224, 224, 224, 0.5)",
  overflow: "hidden",
  margin: "0px",
  position: "relative",
  "&:before": {
    display: "none",
  },
}));

const determineBookmarkColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 0, 0, 0.7)";
    case "High":
      return "rgb(255, 0, 0,0.01)";
    case "Medium":
      return "rgb(255, 140, 0, 0.1)";
    case "Low":
      return "rgba(16, 239, 65, 0.04)";
    case "Informational":
      return "rgb(144, 199, 254,0.1)";
    default:
      return "rgba(16, 239, 65, 0.1)";
  }
};

const determineBookmarBoarderkColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 0, 0)";
    case "High":
      return "rgb(255, 0, 0)";
    case "Medium":
      return "rgb(255, 165, 0)";
    case "Low":
      return "rgba(17, 150, 70, 1)";
    case "Informational":
      return "rgb(124, 179, 234)";
    default:
      return "rgba(16, 239, 65, 0.1)";
  }
};

const determineTextColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 255, 255)";
    case "High":
      return "rgb(255, 0, 0)";
    case "Medium":
      return "rgb(255, 130, 0)";
    case "Low":
      return "rgba(17, 150, 70, 1)";

    case "Informational":
      return "rgb(104, 149, 194)";
    default:
      return "#000000";
  }
};

const Bookmark = styled("div")(({ theme, severity }) => ({
  backgroundColor: determineBookmarkColor(severity),
  color: determineTextColor(severity),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: "4px",
  fontSize: "0.8rem",
  fontWeight: "normal",
  marginLeft: theme.spacing(4),
  border: `1px solid ${determineBookmarBoarderkColor(severity)}`,
}));

const determineCVSSScore = (nessusInfo) => {
  const cvssV3 = nessusInfo["CVSS v3.0 Base Score"];
  const cvssV2 = nessusInfo["CVSS v2.0 Base Score"];
  if (cvssV3 && cvssV3 !== "N/A") return cvssV3;
  if (cvssV2 && cvssV2 !== "N/A") return cvssV2;
  return "N/A";
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "#ff0000";
    case "High":
      return "#ff8c00";
    case "Medium":
      return "#ffa500";
    case "Low":
      return "#17a2b8";
    case "Informational":
      return "#28a745";
    default:
      return "#17a2b8";
  }
};

const NessusAccordion = ({ name, nessusInfo }) => {
  const severity = nessusInfo?.["Risk Factor"] || "N/A";
  const cvssScore = determineCVSSScore(nessusInfo);
  const serviceName = nessusInfo?.["Service Name"] || "N/A";
  const host = nessusInfo?.Host || "N/A";
  const protocol = nessusInfo?.Protocol || "N/A";
  const port = nessusInfo?.Port || "N/A";

  // Sample chart data for severity score
  const chartData = [
    { name: "Severity", value: parseFloat(cvssScore) || 0 }, // Severity value from CVSS score
    { name: "Remaining", value: 10 - (parseFloat(cvssScore) || 0) }, // Remaining percentage for the donut chart
  ];

  // Determine the color for the donut chart based on severity
  const severityColor = getSeverityColor(severity);

  return (
    <CustomizedAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="px-8 mt-0 text-sm text-muted-foreground mb-0"
          sx={{ color: "#1B69AC" }}
          display="flex"
          alignItems="center"
          flexGrow={1}
        >
          {name}
        </Box>
        <Bookmark
          severity={severity}
          style={{
            width: "140px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {severity}
        </Bookmark>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "#F8FAFD" }}>
        <Box>
          {}
          <Card
            sx={{ borderRadius: "0.5rem", boxShadow: 0, marginBottom: "1rem" }}
          >
            <CardContent>
              <Box className="px-8 mt-4 text-xs" sx={{ color: "#1B69AC" }}>
                General Information
              </Box>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Host:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {host}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Protocol:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {protocol}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Port:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {port}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Service Name:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {serviceName}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {/* Donut Chart */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ResponsiveContainer width="100%" height={120}>
                      <PieChart>
                        <Pie
                          data={chartData}
                          innerRadius="65%" // Reduced thickness
                          outerRadius="75%" // Increased outer radius to make the chart bigger
                          dataKey="value"
                        >
                          {chartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={index === 0 ? severityColor : "#eeeeee"}
                            />
                          ))}
                          {/* CVSS Score in the center of the donut chart */}
                          <Label
                            value={cvssScore}
                            position="center"
                            style={{
                              fontSize: "1.5rem", // Increased font size for CVSS score
                              fontWeight: "bold",
                              fill: "black",
                            }}
                          />
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Plugin Information Section */}
          <Card className="mb-5" sx={{ borderRadius: "0.5rem", boxShadow: 0 }}>
            <CardContent>
              <Box className="px-8 mt-4 mb-4 text-xs" sx={{ color: "#1B69AC" }}>
                Plugin Information
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Plugin Name:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo["Plugin Name"] || "N/A"}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    CVE IDs:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo["CVE IDs"]?.length > 0
                      ? nessusInfo["CVE IDs"].join(", ")
                      : "None"}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    CWE:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo.CWE?.length > 0
                      ? nessusInfo.CWE.join(", ")
                      : "None"}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Synopsis:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo.Synopsis || "N/A"}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Description:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo.Description || "N/A"}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="px-8 mt-2 text-xs" sx={{ color: "#000000" }}>
                    Solution:
                  </Box>
                  <Box className="px-8 mt-2 text-xs text-muted-foreground">
                    {nessusInfo.Solution || "N/A"}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </AccordionDetails>
    </CustomizedAccordion>
  );
};

export default NessusAccordion;
