/* eslint-disable no-undef */
const env = process.env.APP_ENV;

const config = {
  dev: {
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_WS_URL: process.env.REACT_APP_WS_URL,
  },
  stage: {
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_WS_URL: process.env.REACT_APP_WS_URL,
  },
  qa: {
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_WS_URL: process.env.REACT_APP_WS_URL,
  },
  prod: {
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_WS_URL: process.env.REACT_APP_WS_URL,
  },
};

export const { APP_API_URL, APP_WS_URL } = config[env] || config.dev;

export const SCAN_SUMMARY_URL = `/scans/summary`;
export const SCAN_URL = `/scans/list`;
export const SCAN_TRIGGER = "/scans/scan";
export const SCAN_VUL_URL = `/scans/data`;
export const USER_LOGIN_URL = `/users/login`;
export const SCAN_REPORT = "scans/report";
export const SCANPDF = "/reports/report";
export const CVE_API = "http://127.0.0.1:5000/api/cve";
export const CVE_API_PATHS = {
  search: "/search",
  cveId: "/",
};
