/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useWebSocket } from "./index";
import { Box } from "@mui/material";
export function Dashboard() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const navigate = useNavigate();
  const { uid } = useSelector((state) => state.auth);

  // Use the custom WebSocket hook
  useWebSocket(uid); // Pass uid to the hook

  useEffect(() => {
    navigate("/dashboard");
  }, []);

  return (
    <div className="grid h-screen w-full">
      {/* Side bar */}
      <Sidebar
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      {/* NavBar and Main */}
      <div
        className={`flex flex-col h-full transition-margin ${
          isSidebarExpanded ? "md:ml-[170px] ml-0" : "md:ml-[60px] ml-0"
        }`}
      >
        {/* NavBar */}
        <Navbar
          isSidebarExpanded={isSidebarExpanded}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
        {/* Main content */}
        <Box
          className="flex-1 overflow-auto"
          sx={{
            paddingX: {
              xxs: "15px",
              xs: "20px",
              xxl: "30px",
            },
            paddingY: {
              xxs: "20px",
              xs: "20px",
            },
          }}
        >
          <Outlet />
        </Box>
      </div>
    </div>
  );
}
