/* eslint-disable react/react-in-jsx-scope */
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { Button, demoVideo } from "./index";

const DashboardNoData = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        // padding: "20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          color: "black",
          fontSize: {
            xs: "25px",
            sm: "30px",
          },
          fontWeight: "bold",
        }}
      >
        Explore the World of Automated VAPT Assessment
      </Box>

      <Box
        sx={{
          textAlign: "center",
          marginTop: "10px",
          fontSize: "14px",
          color: "gray",
        }}
      >
        Discover the power of automated <br />
        Vulnerability Assessment and Penetration Testing (VAPT)
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Button
          className="bg-[#1B69AC] text-white px-8 py-2 rounded-full shadow-lg hover:bg-[#155a91]"
          onClick={() => navigate("/scan")}
        >
          Get Started
        </Button>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          width: "100%",
          maxWidth: "1000px",
          zIndex: -1,
          position: "relative",
        }}
      >
        {/* Adding the looping video */}
        <video
          autoPlay
          loop
          muted
          style={{ width: "100%", borderRadius: "10px" }}
        >
          <source src={demoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
};

export default DashboardNoData;
