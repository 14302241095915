/* eslint-disable react/react-in-jsx-scope */
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  setLoading,
  setFeedback,
  setError,
} from "../../redux/reducers/passwordResetReducer";
import {
  ClipLoader,
  axiosUnAuth,
  password_reset,
  Feedback,
  SecureNEX,
  passwordResetValidationSchema,
  Button,
} from "./index";
import { Box } from "@mui/material";

const PasswordReset = () => {
  const isFeedback = useSelector((state) => state.password_reset.feedback);
  const isLoading = useSelector((state) => state.password_reset.loading);
  const dispatch = useDispatch();
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const abortControllerRef = useRef(null);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handlePasswordReset = async (values, { setSubmitting, resetForm }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));

    try {
      const response = await axiosUnAuth.post(
        password_reset,
        {
          password: values.password,
          uid: uid,
          token: token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        dispatch(setFeedback({ feedback: true }));
      } else {
        console.error("Error resetting password", response);
        navigate("/error", {
          state: { from: location },
          replace: true,
        });
        dispatch(setError({ error: true }));
      }
    } catch (err) {
      console.error("Error resetting password", err);
      navigate("/error", {
        state: { from: location },
        replace: true,
      });
      dispatch(setError({ error: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const passwordFields = [
    {
      id: "password",
      name: "password",
      label: "New Password",
    },
    {
      id: "confirmPassword",
      name: "confirmPassword",
      label: "Confirm New Password",
    },
  ];

  return (
    <div className="flex h-screen">
      {/* Right side */}
      <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img
              src={SecureNEX}
              alt="SecureNEX Logo"
              className="w-32 h-32 relative left-2"
            />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      {/* Left side */}
      <div className="flex-1 bg-white flex justify-center items-center">
        <Box
          className="flex flex-col gap-2"
          sx={{
            width: {
              xxs: "90%",
              xs: "400px",
            },
          }}
        >
          <div className="text-2xl font-semibold text-center mb-4">
            Password Reset
          </div>
          {!isFeedback ? (
            <div className="container p-5 shadow">
              <Formik
                initialValues={initialValues}
                validationSchema={passwordResetValidationSchema}
                onSubmit={handlePasswordReset}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {passwordFields.map((field) => (
                      <div key={field.id} className="mt-3">
                        <label
                          htmlFor={field.id}
                          className="form-label block mb-1"
                          style={{ color: "black", fontWeight: "normal" }}
                        >
                          {field.label}
                        </label>
                        <Field
                          type="password"
                          id={field.id}
                          name={field.name}
                          className="form-control bg-white border border-black rounded p-2 w-full text-black"
                          style={{ backgroundColor: "white", color: "black" }}
                        />
                        <ErrorMessage
                          name={field.name}
                          component="div"
                          className="text-red-500 text-xs mt-1 leading-tight"
                        />
                      </div>
                    ))}

                    <button
                      type="submit"
                      className="bg-black text-white w-full mt-4 p-2 rounded"
                      disabled={isSubmitting || isLoading}
                    >
                      {isLoading ? (
                        <ClipLoader size={21} color={"#fff"} />
                      ) : (
                        "Change password"
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <Box
              className="container p-5 shadow"
              sx={{
                width: {
                  xxs: "90%",
                  xs: "400px",
                },
              }}
            >
              <Feedback message="Password was changed successfully!" />
              <div className="mt-4 w-100 flex flex-col gap-2 items-center">
                <h6 className="text-center pb-2 w-100 font-bold">
                  Login to the Account
                </h6>
                <Link
                  to="/login"
                  className="btn btn-secondary btn-outline-primary text-light w-100"
                >
                  <Button>Login</Button>
                </Link>
              </div>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default PasswordReset;
