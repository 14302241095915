/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from "react-redux";

import PasswordResetRequestForm from "./PasswordResetRequestForm";
import { SecureNEX, Feedback } from "./index";
import { Box } from "@mui/material";

const PasswordResetRequest = () => {
  const isFeedback = useSelector((state) => state.password_reset.feedback);

  return (
    <div className="flex h-screen">
      {/* Right side */}
      <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img
              src={SecureNEX}
              alt="SecureNEX Logo"
              className="w-32 h-32 relative left-2"
            />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      {/* Left side */}
      <div className="flex-1 bg-white flex justify-center items-center">
        <div className="flex flex-col gap-2 w-96">
          <div className="text-2xl font-semibold text-center mb-4">
            Password Reset Request
          </div>
          {!isFeedback ? (
            <Box
              className="container p-5 shadow"
              sx={{
                width: {
                  xxs: "90%",
                  xs: "100%",
                },
              }}
            >
              <PasswordResetRequestForm />
            </Box>
          ) : (
            <Box
              className="container p-5 shadow"
              sx={{
                width: {
                  xxs: "90%",
                  xs: "100%",
                },
              }}
            >
              <Feedback message="Password reset email sent successfully!" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
