import * as Yup from "yup";

// Validation schema for login form
export const loginValidationSchema = Yup.object({
  email: Yup.string().required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});

// Validation schema for resetting the user's password
export const passwordResetValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(30, "Password cannot exceed 30 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return (
        value === this.parent.password ||
        this.createError({ message: "Passwords must match" })
      );
    }),
});

// Validation schema for requesting a password reset
export const PasswordResetRequestValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

// Validation schema for signing up
export const signupValidationSchema = Yup.object({
  firstName: Yup.string().required("Firstname is Required"),
  lastName: Yup.string().required("Lastname is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required"),
  password: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Passcode is Needed"), //changed due to collision between confirm password and password
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirmation-Password is Required"), //changed due to collision between confirm password and password
});
