/* eslint-disable react/react-in-jsx-scope */
import { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import {
  setLoading,
  setFeedback,
  setError,
  setMessage,
} from "../../redux/reducers/passwordResetReducer";
import {
  ClipLoader,
  password_reset_request,
  Label,
  Input,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  axiosUnAuth,
  PasswordResetRequestValidationSchema,
} from "./index";

const PasswordResetRequestForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const isLoading = useSelector((state) => state.password_reset.loading);
  const isFeedback = useSelector((state) => state.password_reset.feedback);

  const handlePasswordResetRequest = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));

    try {
      const response = await axiosUnAuth.post(
        password_reset_request,
        { email: values.email },
        { headers: { "Content-type": "application/json" } }
      );

      if (response.status === 200) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        dispatch(setFeedback({ feedback: true }));
      } else {
        navigate("/error", { state: { from: location }, replace: true });
        dispatch(setError({ error: true }));
      }
    } catch (err) {
      dispatch(
        setMessage({
          message: err.response?.data?.detail || "An error occurred",
        })
      );
      dispatch(setError({ error: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: handlePasswordResetRequest,
    validationSchema: PasswordResetRequestValidationSchema,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center font-bold text-gray-800">
          Enter your email Address
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isFeedback ? (
          <div className="text-center text-green-500 mb-4">
            Password reset email sent successfully!
          </div>
        ) : (
          <div className="grid gap-1 mt-2 item-center space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              className={`${
                formik.errors.email && formik.touched.email
                  ? "border-red-500"
                  : ""
              }`}
            />
            {formik.errors.email && formik.touched.email && (
              <span className="text-xs text-red-500 mt-0">
                {formik.errors.email}
              </span>
            )}
          </div>
        )}
      </CardContent>
      <CardFooter>
        <Button
          type="submit"
          onClick={() => formik.handleSubmit()}
          className="w-full"
          disabled={isLoading || formik.isSubmitting}
        >
          {isLoading ? (
            <ClipLoader size={21} color={"#fff"} />
          ) : (
            "Request Password Reset"
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PasswordResetRequestForm;
