/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { axiosInstance } from "../api/axios";
import { SCAN_URL } from "../api/config";

const ScanList = () => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to fetch scan data
  const getScansData = async () => {
    try {
      const { data } = await axiosInstance.get(SCAN_URL);
      const rows = data?.data?.map((row) => ({
        id: row?.id,
        domain: row?.domain_name,
        created: new Date(row?.created_at), // Store as a Date object
        status: row?.status,
      }));

      // Sort rows by created date from newest to oldest
      const sortedRows = rows.sort((a, b) => b.created - a.created);

      // Format created date as a string
      const formattedRows = sortedRows.map((row) => ({
        ...row,
        created: `${row.created.toDateString()} ${row.created.toLocaleTimeString()}`,
      }));

      setTableData(formattedRows);
    } catch (error) {
      console.error("Error fetching scan data:", error);
    }
  };

  useEffect(() => {
    getScansData();
  }, []);

  useEffect(() => {
    const results = tableData.filter((item) =>
      item.domain.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
  }, [searchTerm, tableData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (data) => {
    const scanId = data.id; // Assuming data.id is the scanId
    const domain = data.domain; // Assuming data.domain exists
    const status = data.status; // Assuming data.status exists

    // Navigate to the historyreportdata page, passing scanId in the URL and other details in state
    navigate(`/historyreportdata/${scanId}`, {
      state: {
        domain,
        status,
      },
    });
  };

  return (
    <Box className="w-full relative">
      <Typography sx={{ marginBottom: 2, fontSize: 20, fontWeight: 600 }}>
        Recent History
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search by domain name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "100%",
            borderRadius: "14px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
            "& .MuiInputBase-input": {
              fontSize: "12px",
              padding: "10px 10px",
            },
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#F8FAFD",
          borderRadius: "16px",
          padding: 2,
          width: "100%",
          marginRight: 2,
        }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/3 font-bold">ID</TableHead>
              <TableHead className="font-bold">Domain Name</TableHead>
              <TableHead className="font-bold">Created At</TableHead>
              <TableHead className="font-bold">Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((data, ind) => (
              <TableRow
                key={ind}
                className="cursor-pointer"
                onClick={() => handleRowClick(data)} // Attach the click event
              >
                <TableCell className="truncate max-w-xs text-xs">
                  {data.id}
                </TableCell>
                <TableCell className="truncate max-w-xs text-xs">
                  {data.domain}
                </TableCell>
                <TableCell className="truncate max-w-xs text-xs text-blue-500">
                  {data.created}
                </TableCell>
                <TableCell className="truncate max-w-xs text-xs">
                  <Box
                    sx={{
                      display: "inline-block",
                      padding: "3px 8px",
                      borderRadius: "6px",
                      color: "black",
                      backgroundColor:
                        data.status === "Vulnerability scan completed"
                          ? "rgba(62, 213, 152, 0.2)"
                          : data.status === "Not Started"
                          ? "rgba(176, 190, 197, 0.2)"
                          : "rgba(255, 167, 38, 0.2)",
                    }}
                  >
                    {data.status}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                fontSize: { xs: "12px", sm: "inherit" }, // Smaller text on mobile
              },
            ".MuiTablePagination-select": {
              fontSize: { xs: "12px", sm: "inherit" },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ScanList;
