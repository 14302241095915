/* eslint-disable react/prop-types */
import React from "react";

const Feedback = ({ message }) => {
  return (
    <div className="container w-100">
      <h4 className="text-center p-3">{message}</h4>
    </div>
  );
};

export default Feedback;
