import { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { APP_WS_URL } from "../api/config";
export const useWebSocket = (uid) => {
  const [message, setMessage] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [wsClient, setWsClient] = useState(null);

  useEffect(() => {
    const wsUrl = `${APP_WS_URL}?id=${uid}`;
    const client = new W3CWebSocket(wsUrl);

    client.onopen = () => {
      console.log("WebSocket connected");
      setWsClient(client);
      setConnectionStatus("Connected");
    };

    client.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      console.log("WebSocket message:", data);
      setMessage(data);
    };

    client.onclose = () => {
      console.log("WebSocket disconnected");
      setWsClient(null);
      setConnectionStatus("Disconnected");
    };

    client.onerror = (error) => {
      console.error("WebSocket error:", error);
      setConnectionStatus("Error");
    };

    return () => {
      if (client.readyState === WebSocket.OPEN) {
        client.close();
      }
    };
  }, [uid]);

  return { message, connectionStatus };
};