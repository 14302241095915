import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  info: 0,
};

const severityCountsReducer = createSlice({
  name: "severityCounts",
  initialState,
  reducers: {
    setSeverityCounts: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSeverityCounts } = severityCountsReducer.actions;
export default severityCountsReducer.reducer;
