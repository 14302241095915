/* eslint-disable react/react-in-jsx-scope */
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { axiosInstance } from "./api/axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Login } from "./new-components/Auth/Login";
import { PrivateRoute } from "./components/common/PrivateRoute";
import { Dashboard } from "./new-components/Dashboard/Dashboard";
import { SignUpComponent } from "./new-components/Auth/SignUp";
import { ScanComponent as Scan } from "./new-components/ScanCompoent";
import ErrorPage from "./pages/Error";
import EmailVerification from "./new-components/Auth/EmailVerification";
import PasswordReset from "./new-components/Auth/PasswordReset";
import PasswordResetRequest from "./new-components/Auth/PasswordResetRequest";
import { DashboardPage } from "./new-components/DashboardPage/DashboardPage";
import { ProfileComponent } from "./new-components/Profile/Profile";
import ScanList from "./new-components/ScanList";
import HistoryReportData from "../src/pages/historyreportdata";
import CVE_details from "./new-components/CVE/Cve_details";
import {
  setAuth,
  setName,
  setIsVerified,
  setUID,
} from "./redux/reducers/authReducer";
import { token_verification } from "./api/auth";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(token_verification);

        if (response.status === 200) {
          const { email, is_verified, id, full_name } =
            response.data.data || {};

          dispatch(
            setAuth({
              loggedIn: true,
              email: email || "",
            })
          );
          dispatch(
            setIsVerified({
              isVerified: is_verified,
            })
          );
          dispatch(setUID({ uid: id }));
          dispatch(setName({ full_name: full_name }));

          navigate(location.pathname, {
            state: { from: location },
            replace: true,
          });
        }
      } catch (err) {
        console.error("Error fetching data:", err);

        dispatch(
          setAuth({
            loggedIn: false,
            email: "",
          })
        );
      }
    }
    fetchData();
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<SignUpComponent />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route
        path="/verification"
        element={
          <PrivateRoute>
            <EmailVerification />
          </PrivateRoute>
        }
      />
      <Route
        path="/password-reset-request"
        element={<PasswordResetRequest />}
      />
      <Route
        exact
        path="/password-reset/:uid/:token"
        element={<PasswordReset />}
      />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route exact path="dashboard" element={<DashboardPage />} />
        <Route exact path="scan" element={<Scan />} />
        <Route exact path="profile" element={<ProfileComponent />} />
        <Route exact path="scan-list" element={<ScanList />} />

        <Route
          exact
          path="/historyreportdata/:scanId" // Route accepting scanId as a URL parameter
          element={<HistoryReportData />}
        />
        <Route
          exact
          path="/CVE-details" // Route accepting scanId as a URL parameter
          element={<CVE_details />}
        />
      </Route>
    </Routes>
  );
};

export default App;
