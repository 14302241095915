/* eslint-disable react/react-in-jsx-scope */
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import TaskOutlined from "@mui/icons-material/TaskOutlined";
import { ResponsiveContainer } from "recharts";
import { Skeleton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import LowRiskMessage from "../pages/LowRiskMessage";
import CustomBarChart from "../pages/CustomBarChart";
import CustomPieChart from "../pages/CustomPieChart";
import { axiosInstance } from "../api/axios";
import { SCAN_REPORT, SCAN_URL } from "../api/config";
import NessusAccordion from "../pages/NessusAccordian";
import { Button } from "../components/ui/button";
import CustomAccordian from "../pages/CustomAccordian";

const HistoryReportData = () => {
  const { scanId } = useParams(); // Get scanId from URL

  const [loading, setLoading] = useState(true);
  const [scanReportData, setScanReportData] = useState([]);
  const [highestSeverity, setHighestSeverity] = useState("");
  const [chartData, setChartData] = useState([]);
  const [domain, setDomain] = useState("");
  const [status, setStatus] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const { uid } = useSelector((state) => state.auth);

  const generatePdf = async () => {
    if (!scanId) {
      toast.error("No scan ID available to generate PDF.");
      return;
    }

    try {
      setLoadingPdf(true);
      const response = await axiosInstance.post(
        "reports/report",
        {
          file_type: "pdf",
          user_id: uid,
          scan_id: scanId,
        },
        {
          responseType: "blob",
          headers: {
            Cookie: document.cookie, // Automatically includes accessToken and refreshToken if stored in cookies
          },
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `ScanReport_${scanId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("PDF generated successfully!");
    } catch (error) {
      toast.error("Failed to generate PDF. Please try again.");
      console.error(
        "Error generating PDF:",
        error.response?.data || error.message
      );
    } finally {
      setLoadingPdf(false);
    }
  };

  // Function to fetch scan report
  const getScanReport = async (scanId) => {
    try {
      const postData = { scan_id: scanId, page: 1, size: 25 };
      const response = await axiosInstance.post(SCAN_REPORT, postData);
      const scanData = response?.data?.data?.scan_data?.data || [];

      const sevearitycounts = response?.data?.data?.severity_counts;
      // Set scan report data
      setScanReportData(scanData);

      try {
        const currentHighestSeverity = response?.data?.data?.risk_level;

        setHighestSeverity(currentHighestSeverity.toLowerCase());

        setChartData([
          {
            label: "Critical",
            value: sevearitycounts.critical,
            color: "#f17e92",
          },
          { label: "High", value: sevearitycounts.high, color: "#fbd5da" },
          { label: "Medium", value: sevearitycounts.medium, color: "#bedcfe" },
          { label: "Low", value: sevearitycounts.low, color: "#90c7fe" },
          { label: "Info", value: sevearitycounts.info, color: "#3b86f7" },
        ]);
      } catch (error) {
        console.error("Error occurred:", error);
        // Set default values on error
        setHighestSeverity("Info");
        setChartData([
          { label: "Critical", value: 0, color: "#f17e92" },
          { label: "High", value: 0, color: "#fbd5da" },
          { label: "Medium", value: 0, color: "#bedcfe" },
          { label: "Low", value: 0, color: "#90c7fe" },
          { label: "Info", value: 0, color: "#3b86f7" },
        ]);
      }

      setLoading(false);
    } catch (error) {
      console.error(
        "Error while fetching scan report:",
        error.response?.data || error.message
      );
      setLoading(false);
    }
  };

  // Function to fetch scan details
  const getScansData = async () => {
    try {
      const { data } = await axiosInstance.get(SCAN_URL);
      const scanDetails = data?.data?.find((row) => row.id === scanId);

      if (scanDetails) {
        setDomain(scanDetails.domain_name);
        setStatus(scanDetails.status);
      }
    } catch (error) {
      console.error("Error fetching scan data:", error);
    }
  };

  useEffect(() => {
    getScanReport(scanId);
    getScansData(); // Fetch additional scan details
  }, [scanId]);

  return (
    <Box>
      <>
        {/* Other components */}
        <ToastContainer
          position="top-right"
          autoClose={3000} // Closes after 3 seconds
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
      {loading ? (
        <Box sx={{ mt: 5 }}>
          <Stack spacing={1}>
            {}
            <Skeleton variant="text" width="40%" height={30} />

            {/* Skeleton for the description */}
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />

            {/* Skeleton for a larger area, like a card or a detailed section */}
            <Skeleton variant="rectangular" height={150} />

            {/* Skeleton for footer or action buttons */}
            <Skeleton variant="text" width="20%" height={30} />
          </Stack>
        </Box>
      ) : (
        <Box className="mt-5">
          <Typography variant="h7" sx={{ color: "Black" }}>
            Detailed scan report
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="min-w-24 bg-[#1B69AC] text-white"
              style={{ zIndex: 2 }}
              startIcon={<PictureAsPdfIcon />}
              onClick={generatePdf}
              disabled={loadingPdf} // Disable button while loading
            >
              {loadingPdf ? "Generating..." : "Download PDF"}
            </Button>
          </Box>
          <LowRiskMessage riskLevel={highestSeverity} />

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "20% auto",
              gap: 5,
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "repeat(3, 1fr)",
                gap: 2,
                maxHeight: "700px",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: "#F8FAFD",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateRows: "1fr 1fr",
                    gap: 1,
                    padding: 2,
                  }}
                >
                  <Box
                    sx={{ display: "grid", gridTemplateColumns: "20% auto" }}
                  >
                    <TaskOutlined
                      sx={{
                        color: "hsl(var(--muted-foreground))",
                        alignSelf: "center",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Scan details
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Scan ID: {scanId || "Loading..."}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Domain Name: {domain || "Loading..."}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{ display: "grid", gridTemplateColumns: "20% auto" }}
                  >
                    <TaskOutlined
                      sx={{
                        color: "hsl(var(--muted-foreground))",
                        alignSelf: "center",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Status
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#3ED598" }}>
                        {status || "Loading..."}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>

              <Card
                variant="outlined"
                sx={{
                  backgroundColor: "#F8FAFD",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <ResponsiveContainer width="100%" height={200}>
                  <CustomBarChart data={chartData} />
                </ResponsiveContainer>
              </Card>

              <Card
                variant="outlined"
                sx={{
                  backgroundColor: "#F8FAFD",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <ResponsiveContainer width="100%" height={240}>
                  <CustomPieChart
                    data={chartData}
                    innerRadius={50}
                    outerRadius={80}
                  />
                </ResponsiveContainer>
              </Card>
            </Box>

            <Box sx={{ padding: 0, margin: 0 }}>
              {scanReportData.map((report, index) => {
                // Render only if data is not null or empty
                if (report || report.name === "VA Scan Results") {
                  return (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#FFFFF",
                        boxShadow: "none",
                        border: "none",
                        margin: 0, // Set margin to 0 to avoid spacing
                        padding: 0, // Ensure no padding is applied
                        mb: 1,
                      }}
                    >
                      {/* Conditionally render NessusAccordion if report.name is 'VA Scan Results' */}
                      {report.name === "VA Scan Results" ? (
                        <NessusAccordion
                          name={report.nessus_info.Synopsis}
                          nessusInfo={report.nessus_info}
                        />
                      ) : (
                        scanReportData.map((scan, index) => (
                          <CustomAccordian key={index} data={scan} />
                        ))
                      )}
                    </Card>
                  );
                }
                return null; // Skip if data is null or undefined
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HistoryReportData;
