/* eslint-disable react/react-in-jsx-scope */
import { useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Box } from "@mui/material";

import {
  setLoading,
  setFeedback,
  setError,
  setMessage,
} from "../../redux/reducers/registrationReducer";
import {
  Button,
  Input,
  Label,
  SecureNEX,
  axiosInstance,
  registration,
  signupValidationSchema,
} from "./index";

export const SignUpComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.registration.loading);
  const error = useSelector((state) => state.registration.error);
  const message = useSelector((state) => state.registration.message);
  const abortControllerRef = useRef(null);

  const handleRegistration = async (values, { setSubmitting, resetForm }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));
    dispatch(setError({ error: false }));
    dispatch(setMessage({ message: "" }));

    try {
      const user_data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirm: values.confirmPassword,
      };
      const response = await axiosInstance.post(registration, user_data, {
        headers: { "Content-type": "application/json" },
      });

      if (response.status === 201) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        navigate("/login", { state: { from: location }, replace: true });
      } else {
        dispatch(setLoading({ loading: false }));
        dispatch(setFeedback({ feedback: true }));
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.response.data.email) {
          dispatch(setError({ error: true }));
          dispatch(
            setMessage({ message: "Error while signing up. Please try again." })
          );
        } else {
          dispatch(setError({ error: true }));
          dispatch(
            setMessage({ message: "Error while signing up. Please try again." })
          );
        }
      } else {
        dispatch(setError({ error: true }));
        dispatch(
          setMessage({ message: "Error while signing up. Please try again." })
        );
      }
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupValidationSchema,
    onSubmit: handleRegistration,
  });

  const inputFields = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "John",
      type: "text",
      testId: "first-name-input",
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "Doe",
      type: "text",
      testId: "last-name-input",
    },
    {
      label: "Email",
      name: "email",
      placeholder: "sample@email.com",
      type: "email",
      testId: "email-input",
    },
    {
      label: "Password",
      name: "password",
      placeholder: "Password",
      type: "password",
      testId: "password-input",
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      placeholder: "Confirm Password",
      type: "password",
      testId: "confirm-password-input",
    },
  ];

  return (
    <div className="flex h-screen">
      {/* Right Side */}
      <div className="flex-1 hidden md:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img src={SecureNEX} className="w-32 h-32 relative left-2" alt="" />
          </div>
          <div className="text-2xl lg:text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      {/* Left Side */}
      <div className="bg-white flex-1 flex justify-center items-center">
        <Box
          className="flex flex-col gap-2"
          sx={{ width: { xs: "300px", sm: "350px", md: "300px", lg: "350px" } }}
        >
          <div className="text-2xl text-center font-semibold ">
            Create an account
          </div>
          <div className="mb-4 text-muted-foreground text-sm text-center">
            Enter your details for creating an account
          </div>

          {error && <div className="text-sm text-red-500 mb-2">{message}</div>}

          {inputFields.map((field) => (
            <div key={field.name} className="mt-2">
              <Label>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                data-testid={field.testId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[field.name]}
                className={formik.errors[field.name] ? "border-red-500" : ""}
              />
              <div className="text-sm text-red-500">
                {formik.touched[field.name] && formik.errors[field.name]}
              </div>
            </div>
          ))}

          <Button
            type="submit"
            data-testid="create-account" //inserted id for testing purposes
            onClick={formik.handleSubmit}
            className="mt-4"
            disabled={isLoading}
          >
            {isLoading ? "Creating Account..." : "Create Account"}
          </Button>

          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            Already have an account?{" "}
            <Link to="/login">
              <span className="text-blue-500 cursor-pointer">Login</span>
            </Link>
          </div>
        </Box>
      </div>
    </div>
  );
};
