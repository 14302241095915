/* eslint-disable react/prop-types */
import React from "react";
import {
  ScanSearch,
  LayoutDashboard,
  LayoutList,
  SquareUser,
  ShieldCheck,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  SecureNEX,
} from "./index";

const Sidebar = ({ isSidebarExpanded, setIsSidebarExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (path) => {
    navigate(path);
    if (window.innerWidth < 768) {
      setIsSidebarExpanded(false);
    }
  };

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const navItems = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: <LayoutDashboard className="size-5" />,
    },
    { path: "/scan", label: "Scan", icon: <ScanSearch className="size-5" /> },
    {
      path: "/scan-list",
      label: "Scan History",
      icon: <LayoutList className="size-5" />,
    },
    {
      path: "/CVE-details",
      label: "CVE Details",
      icon: <ShieldCheck className="size-5" />,
    },
    {
      path: "/profile",
      label: "Profile",
      icon: <SquareUser className="size-5" />,
    },
  ];

  return (
    <aside
      className={`fixed top-0 left-0 z-20 h-full flex flex-col ${
        isSidebarExpanded ? "w-[170px]" : "w-[60px]"
      } border-r bg-white shadow-lg transition-width md:block ${
        isSidebarExpanded ? "block" : "hidden"
      }`}
    >
      <div className="border-b p-2">
        <Button
          variant="outline"
          size="icon"
          aria-label="SecBot"
          onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
        >
          <img src={SecureNEX} alt="Logo Image" className="w-16 mx-auto" />
        </Button>
      </div>

      <nav className="grid gap-1 p-2">
        {navItems.map((item) => (
          <div
            key={item.path}
            className={`rounded-lg py-2 px-1 ${
              isActivePath(item.path) && "bg-muted"
            }`}
          >
            <Tooltip key={item.path}>
              <TooltipTrigger asChild>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleNavClick(item.path)}
                >
                  <Button variant="ghost" size="icon" aria-label={item.label}>
                    {item.icon}
                  </Button>
                  {isSidebarExpanded && (
                    <span className="ml-2">{item.label}</span>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent side="right" sideOffset={5}>
                {item.label}
              </TooltipContent>
            </Tooltip>
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
