/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from "react";
import { Card, Typography, Box } from "@mui/material";
import TaskOutlined from "@mui/icons-material/TaskOutlined";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Legend,
} from "recharts";
import { ToastContainer } from "react-toastify";

import { SecureNEX, Nodelink } from "./index";

const DashboardData = () => {
  const { full_name } = useSelector((state) => state.auth);
  const { total_scans, mean_time, total_domains } = useSelector(
    (state) => state.scanMetrics
  );
  const { critical, high, medium, low, info } = useSelector(
    (state) => state.severityCounts
  );
  const items = [
    { label: "Critical", value: 0, color: "#f17e92" },
    { label: "High", value: 0, color: "#fbd5da" },
    { label: "Medium", value: 0, color: "#bedcfe" },
    { label: "Low", value: 0, color: "#90c7fe" },
    { label: "Info", value: 0, color: "#3b86f7" }, // Added Info category
  ];

  const chartData = useMemo(() => {
    const data = {
      critical,
      high,
      medium,
      low,
      info,
    };

    return Object.keys(data).map((key) => ({
      label: key,
      value: data[key],
      color:
        items.find((item) => item.label.toLowerCase() === key)?.color ||
        "defaultColor", // Default color if none is found
    }));
  }, [items]);

  const cardData = [
    {
      value: total_scans,
      label: "Total scans",
      icon: <TaskOutlined sx={{ color: "white" }} />,
    },
    {
      value: mean_time,
      label: "Mean Time",
      icon: <TaskOutlined sx={{ color: "white" }} />,
    },
    {
      value: total_domains,
      label: "Domains",
      icon: <TaskOutlined sx={{ color: "white" }} />,
    },
  ];
  return (
    <Box
    // sx={{ padding: { xs: "20px 15px", sm: "30px 20px" } }}
    >
      <ToastContainer position="top-right" autoClose={2000} theme="dark" />
      {/* Welcome Bannar */}
      <Box
        className="grid mt-0 mb-4 relative overflow-hidden sm:grid-cols-[60%_40%]"
        sx={{
          backgroundColor: "#14212D",
          borderRadius: "16px",
        }}
      >
        {/* Left side */}
        <Box className="grid grid-rows-2 mt-12 ml-28 max-xl:ml-24 max-lg:ml-20 max-md:ml-10 max-sm:ml-8 max-sm:mr-8 max-w-xl">
          {/* Welcome Text */}
          <Box
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.15rem",
                  lg: "1.25rem",
                  xl: "1.25rem",
                },
                mt: 0,
              }}
            >
              Hello {full_name}.. Welcome Back !!
            </Typography>

            <Box
              className="text-sm max-sm:text-[12px] max-md:[13px]"
              sx={{
                color: "white",
                fontStyle: "italic",
              }}
            >
              Try performing an automated VAPT assessment
            </Box>
          </Box>
          {/* 3 Component */}
          <Box className="grid grid-cols-3 mt-0 ml-0 mb-8 gap-2 sm:gap-5">
            {cardData.map((card, index) => (
              <Card
                key={index}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  boxShadow: "none",
                  maxWidth: {
                    md: "130px",
                    lg: "200px",
                  },
                  borderRadius: "12px",
                  display: "flex", // Add flex to align items
                  flexDirection: "column", // Stack items vertically
                  justifyContent: "center", // Center items vertically
                  alignItems: "center", // Center items horizontally
                  padding: {
                    sm: "8px",
                    md: "10px",
                    lg: "13px",
                    xl: "16px",
                    xxl: "16px",
                  }, // Add some padding
                  paddingY: {
                    xs: "5px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column-reverse",
                      lg: "row",
                    },
                    gap: {
                      xs: "10px",
                      lg: 0,
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {card.icon}
                  </Box>
                  <Box
                    sx={{
                      textAlign: {
                        xs: "center",
                        lg: "right",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "1rem",
                          md: "1.15rem",
                          lg: "1.25rem",
                        },
                      }}
                    >
                      {card.value}
                    </Typography>
                    <Box
                      className="text-xs"
                      sx={{
                        color: "white",
                        fontStyle: "italic",
                      }}
                    >
                      {card.label}
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))}
          </Box>
        </Box>
        {/* Right size */}
        <Box
          className="flex items-center justify-end mr-24 max-sm:justify-center max-sm:mr-0 max-md:mr-6 max-lg:mr-12 max-xl:mr-20"
          sx={{ paddingBottom: { xs: "20px", sm: 0 } }}
        >
          <img
            src={SecureNEX}
            alt="Logo"
            className="h-28"
            style={{
              filter: "brightness(1.2)",
              zIndex: 2,
            }}
          />

          <Box
            component="img"
            src={Nodelink}
            alt="Logo"
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              position: "absolute",
              height: {
                xs: "1000px",
                sm: "500px",
                md: "450px",
                lg: "500px",
                xl: "600px",
              }, // Adjust height as needed
              width: "60%",
              right: "0%",
              zIndex: 1,
              overflow: "hidden",
              opacity: 0.2,
              transition: "transform 0.3s ease-in-out",
              filter: "brightness(1)",
              mt: 8,
              "&:hover": {
                transform: "translate(12px, 12px)", // Matches translate-x-3 and translate-y-3
              },
            }}
          />
        </Box>
      </Box>

      <Typography
        variant="h6"
        sx={{
          color: "Black",
          fontWeight: "bold",
          justifySelf: "center",
        }}
      >
        Detailed scan report
      </Typography>

      <Box
        className="grid grid-rows-2 sm:grid-cols-2 gap-5 mt-8"
        sx={{ maxHeight: "720px" }}
      >
        {/* Pie Chart */}
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#F8FAFD",
            boxShadow: "none",
            border: "1px solid rgba(224, 224, 224, 0.5)",
            borderRadius: "12px",
          }}
        >
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                // fill="currentColor"
                isAnimationActive={true}
                radius={[4, 4, 0, 0]}
                // cx={500}
                // cy={200}
                innerRadius={50}
                outerRadius={80}
                // className="fill-primary"
                // label={({ name, percent, index }) => `${chartData[index].label} ${(percent * 100).toFixed(0)}%`}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => [`${props.payload.label}`]}
              />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                formatter={(value) => (
                  <span
                    style={{
                      color: "gray",
                      fontSize: "8px",
                      margin: "0 1px",
                    }}
                  >
                    {value}
                  </span>
                )}
                payload={chartData.map((item) => ({
                  id: item.label,
                  type: "square",
                  value: `${item.label}`,
                  color: item.color,
                }))}
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Card>

        {/* Bar chart */}
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#F8FAFD",
            boxShadow: "none",
            border: "1px solid rgba(224, 224, 224, 0.5)",
            borderRadius: "12px",
          }}
        >
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={chartData}
              layout="horizontal" // Use 'vertical' if your bars are vertical
              margin={{ top: 30, right: 25, bottom: 0, left: 0 }} // Adjust margins to fit your design
            >
              <XAxis
                dataKey="label"
                stroke="#888888"
                fontSize={8}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={8}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <Bar
                dataKey="value"
                fill="currentColor"
                radius={[2, 2, 0, 0]}
                width={10}
                barSize={35}
                // className="fill-primary"
              />
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Box>
    </Box>
  );
};

export default DashboardData;
