/* eslint-disable react/prop-types */
import React from "react";
import { AvatarIcon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Button,
  logout,
  axiosInstance,
} from "./index";

const Navbar = ({ isSidebarExpanded, setIsSidebarExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = useSelector((state) => state.auth);

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post(logout);

      if (response.status === 200) {
        navigate("/login", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/error", {
          state: { from: location },
          replace: true,
        });
      }
    } catch (err) {
      navigate("/error", {
        state: { from: location },
        replace: true,
      });
    }
  };
  return (
    <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background justify-between mx-4">
      <nav className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          className="mr-2 md:hidden"
          onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
        >
          <HamburgerMenuIcon className="h-6 w-6" />
        </Button>
      </nav>
      <h1 className="text-xl font-semibold">SecBot</h1>
      {/*Created a div for Logout button*/}
      <div className="title-bar py-1 items-center px-2 font-semibold text-lg">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="overflow-hidden rounded-full"
            >
              <AvatarIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem disabled>
              {email ? `Email: ${email}` : "Email not available"}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Support</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Navbar;
