/* eslint-disable react/prop-types */
import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const CustomPieChart = ({ data, width, height, innerRadius, outerRadius }) => {
  const severityColors = [
    { label: "Critical", color: "#f17e92" },
    { label: "High", color: "#fbd5da" },
    { label: "Medium", color: "#bedcfe" },
    { label: "Low", color: "#90c7fe" },
    { label: "Info", color: "#3b86f7" },
  ];

  // Function to get color based on severity
  const getColorForSeverity = (label) => {
    const colorItem = severityColors.find(
      (item) => item.label.toLowerCase() === label.toLowerCase()
    );
    return colorItem ? colorItem.color : "#000"; // Default to black if no match
  };

  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="label" // This ensures label is used for legend
        isAnimationActive={true}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={getColorForSeverity(entry.label)} />
        ))}
      </Pie>
      <Tooltip formatter={(value, name, props) => [`${props.payload.label}`]} />
      <Legend
        layout="horizontal"
        align="center"
        verticalAlign="bottom"
        formatter={(value) => (
          <span style={{ color: "gray", fontSize: "8px", margin: "0 1px" }}>
            {value}
          </span>
        )}
        payload={data.map((item) => ({
          id: item.label,
          type: "square",
          value: `${item.label}`,
          color: getColorForSeverity(item.label),
        }))}
        wrapperStyle={{
          display: "flex",
          justifyContent: "center",
          color: "black",
        }}
      />
    </PieChart>
  );
};

export default CustomPieChart;
