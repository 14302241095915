/* eslint-disable react/react-in-jsx-scope */
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  setAuth,
  setIsVerified,
  setUID,
  setName,
} from "../../redux/reducers/authReducer";
import {
  setFeedback,
  setLoading,
  setMessage,
} from "../../redux/reducers/loginReducer";
import {
  axiosInstance,
  SecureNEX,
  Button,
  Input,
  useToast,
  login,
  Label,
  loginValidationSchema,
} from "./index";

export const Login = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login.loading);
  const isFeedback = useSelector((state) => state.login.feedback);
  const message = useSelector((state) => state.login.message);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (form) => {
      handleLogin(form);
    },
  });

  const handleLogin = async ({ email, password }) => {
    dispatch(setLoading({ loading: true }));
    try {
      const response = await axiosInstance.post(
        login,
        { email, password },
        { headers: { "Content-type": "application/json" } }
      );

      if (response.status === 200) {
        const { is_verified, id } = response.data.data;
        const userData = response.data.data;

        const authPayload = {
          loggedIn: true,
          email: userData.email || "",
          name: userData.full_name || "",
        };

        dispatch(setAuth(authPayload));
        dispatch(setIsVerified({ isVerified: is_verified }));
        dispatch(setUID({ uid: id }));
        dispatch(setName({ full_name: userData.full_name }));

        formik.resetForm();

        if (userData.is_verified) {
          navigate("/dashboard", { state: { from: location }, replace: true });
        } else {
          navigate("/verification", {
            state: { from: location },
            replace: true,
          });
        }
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(setFeedback({ feedback: true }));
        dispatch(setMessage({ message: err.response.data.detail }));
      } else {
        toast({
          variant: "destructive",
          title: "Sorry, cannot login",
          description:
            "Getting error when trying to login with these credentials",
        });
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };
  const inputFields = [
    {
      label: "Email",
      name: "email",
      placeholder: "name@example.com",
      type: "text",
    },
    {
      label: "Password",
      name: "password",
      placeholder: "Password",
      type: "password",
    },
  ];
  return (
    <div className="flex h-screen">
      {/* Right Side */}
      <div className="flex-1 hidden md:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img src={SecureNEX} className="w-32 h-32 relative left-2" alt="" />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      {/* Left Side */}
      <div className="flex-1 bg-white flex  justify-center items-center">
        <div className="flex flex-col gap-2 w-72">
          <div className="text-2xl font-semibold text-center">Login</div>
          <div className="mb-4 text-muted-foreground text-center text-sm">
            Enter your Email & Password for Login
          </div>
          {inputFields.map((field) => (
            <div key={field.name} className="mt-2">
              <Label>{field.label}</Label>
              <Input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                onChange={formik.handleChange}
                className={`${
                  formik.errors[field.name] ? "border-2 border-red-400" : ""
                }`}
              />
              <div className="text-sm text-red-500 mt-0">
                {formik.errors[field.name]}
              </div>
            </div>
          ))}

          <Button
            type="submit"
            className="mt-2"
            onClick={formik.handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </Button>

          {isFeedback && (
            <div className="text-sm text-red-500 mt-2 text-center">
              {message}
            </div>
          )}

          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            Don&apos;t have an account?
            <Link to="/registration">
              <span className="text-blue-500 cursor-pointer"> Sign Up</span>
            </Link>
          </div>
          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            <Link to="/password-reset-request">
              <span className="text-blue-500 cursor-pointer">
                Forgot Password?
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
