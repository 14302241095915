/* eslint-disable react/react-in-jsx-scope */
import { Box } from "@mui/material";
import AI from "../assets/images/worldb.png";

// Main Component to Render AI Image with Title and Description
const FeatureCards = () => {
  return (
    <Box
      sx={{
        position: "relative",
        padding: 0,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: "background.default",
      }}
    >
      {/* AI Image */}
      <img
        src={AI}
        alt="AI representation"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          borderRadius: "12px",
          marginTop: "-100px",
          zIndex: 1,
          position: "relative",
        }}
      />
    </Box>
  );
};

export default FeatureCards;
