/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, TextField, Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";

import {
  setName,
  AvatarImage,
  name_change,
  Button,
  nameChangeValidationSchema,
  CustomCard,
} from "./index";

const NameChangeForm = () => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();

  const toggleEdit = () => setIsEditable((prev) => !prev);
  const { email, isVerified, full_name, uid } = useSelector(
    (state) => state.auth
  );

  const handleSubmit = async (values) => {
    if (!isEditable) {
      toggleEdit();
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${name_change}`,
        {
          user_id: uid,
          first_name: values.firstName,
          last_name: values.lastName,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        toast.success("Name changed successfully!");
        dispatch(
          setName({ full_name: `${values.firstName} ${values.lastName}` })
        );
        toggleEdit();
      } else {
        throw new Error(response.data.message || "Unknown error");
      }
    } catch (error) {
      toast.error(
        `Failed to update name: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  return (
    <>
      <CustomCard title="Account">
        <Formik
          initialValues={{
            firstName: full_name.split(" ")[0] || "",
            lastName: full_name.split(" ")[1] || "",
          }}
          validationSchema={nameChangeValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Box
                sx={{
                  paddingX: {
                    lg: 3,
                    xl: 5,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: {
                      xxs: "column",
                      xs: "row",
                    },
                  }}
                >
                  <AvatarSection isVerified={isVerified} />
                  <Box sx={{ width: "100%" }}>
                    <EmailField email={email} />
                    <NameFields
                      isEditable={isEditable}
                      errors={errors}
                      touched={touched}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    marginTop: 3,
                    justifyContent: { xxs: "center", xs: "flex-end" },
                  }}
                >
                  <Button type="submit" className="w-full sm:w-52">
                    {isEditable ? "Save Changes" : "Edit Profile"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomCard>
      <Toaster
        position="top-right"
        gutter={8}
        toastOptions={{
          style: { background: "#363636", color: "#fff" },
          success: {
            duration: 3000,
            iconTheme: { primary: "green", secondary: "black" },
          },
        }}
      />
    </>
  );
};

const AvatarSection = ({ isVerified }) => (
  <Grid
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1,
      marginRight: {
        xxs: 0,
        xs: 3,
        sm: 4,
      },
      marginBottom: 1,
    }}
  >
    <Typography
      variant="body1"
      sx={{ color: "text.secondary", textAlign: "center" }}
    >
      Avatar
    </Typography>
    <Avatar
      sx={{ width: 64, height: 64, border: "2px solid #f5f5f5" }}
      src={AvatarImage}
      alt="Profile"
    />
    {isVerified && (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CheckCircleIcon sx={{ color: "green", marginRight: 1, width: 20 }} />
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Verified
        </Typography>
      </Box>
    )}
  </Grid>
);

const EmailField = ({ email }) => (
  <TextField
    fullWidth
    label="Email Address"
    variant="outlined"
    value={email}
    InputProps={{ readOnly: true }}
    sx={{
      marginTop: 1,
      input: { color: "rgba(0, 0, 0, 0.6)" },
    }}
  />
);

const NameFields = ({ isEditable, errors, touched }) => {
  const nameFields = [
    { name: "firstName", label: "First Name", placeholder: "Enter First Name" },
    { name: "lastName", label: "Last Name", placeholder: "Enter Last Name" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xxs: "column",
          xs: "row",
        },
        gap: 2,
        marginTop: 2,
      }}
    >
      {nameFields.map(({ name, label, placeholder }) => (
        <Box key={name} sx={{ width: "100%" }}>
          <Field name={name}>
            {({ field }) => (
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                placeholder={placeholder}
                {...field}
                error={Boolean(errors[name] && touched[name])}
                helperText={<ErrorMessage name={name} />}
                InputProps={{ readOnly: !isEditable }}
                sx={{
                  input: { color: isEditable ? "black" : "rgba(0, 0, 0, 0.6)" },
                }}
              />
            )}
          </Field>
        </Box>
      ))}
    </Box>
  );
};

AvatarSection.propTypes = {
  isVerified: PropTypes.bool.isRequired,
};

EmailField.propTypes = {
  email: PropTypes.string.isRequired,
};

NameFields.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default NameChangeForm;
