/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Box } from "@mui/material";

// Risk levels with corresponding colors and messages
const riskStyles = {
  high: {
    color: "#D9534F", // Red for high risk
    backgroundColor: "rgba(255, 0, 0, 0.1)",
    title: "High Risk - Immediate Action Required",
    message:
      "Your system is at high risk. Immediate action is required to secure it.",
  },
  critical: {
    color: "#D9534F", // Red for Critical risk
    backgroundColor: "rgba(255, 0, 0, 0.1)",
    title: "Critical Risk - Urgent Action Required",
    message:
      "Critical vulnerabilities detected. Urgent action is required to protect your system.",
  },
  medium: {
    color: "#F0AD4E", // Orange for medium risk
    backgroundColor: "rgba(255, 165, 0, 0.1)",
    title: "Medium Risk - Attention Needed",
    message:
      "Your system has medium-level risks. Review and mitigate the vulnerabilities as soon as possible.",
  },
  low: {
    color: "#119646", // Green for low risk
    backgroundColor: "rgba(16, 239, 65, 0.1)",
    title: "Low Risk - Congratulations, You're Safe",
    message: "Your system is safe. Currently, no immediate action is required.",
  },
  info: {
    color: "#119646",
    backgroundColor: "rgba(16, 239, 65, 0.1)",
    title: "Info - Congratulations, You're Safe",
    message: "Your system is running normally. No risks detected at this time.",
  },
  loading: {
    color: "#2e2e2e",
    backgroundColor: "rgba(211, 211, 211, 0.1)",
    title: "Scan in progress",
    message:
      "The scan is currently in progress. Please wait while we analyze your system.",
  },
};

const RiskMessage = ({ riskLevel }) => {
  // Default to "Info" if the riskLevel prop is not provided or doesn't match any keys
  const { color, backgroundColor, title, message } =
    riskStyles[riskLevel] || riskStyles.loading;

  return (
    <Box
      className="grid grid-rows-2 mt-5 mb-5"
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: "8px",
      }}
    >
      <Box className="mt-1" sx={{ textAlign: "center", color: color }}>
        {title}
      </Box>
      <Box
        className="px-8 mt-1 text-xs text-muted-foreground mb-1"
        sx={{ textAlign: "center" }}
      >
        {message}
      </Box>
    </Box>
  );
};

export default RiskMessage;
