import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total_scans: 0, // Default value
  mean_time: "0m 0s",
  total_domains: 0,
};

const scanMetricsReducer = createSlice({
  name: "scanMetrics",
  initialState,
  reducers: {
    setScanMetrics: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setScanMetrics } = scanMetricsReducer.actions;
export default scanMetricsReducer.reducer;
