/* eslint-disable react/react-in-jsx-scope */
import { Box } from "@mui/material";
import { PasswordChangeForm, NameChangeForm } from "./index";

export const ProfileComponent = () => {
  return (
    <Box
      sx={{
        margin: "0 auto",
        maxWidth: "1300px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Account Section */}
      <NameChangeForm />

      {/* Password Reset Section */}
      <PasswordChangeForm />
    </Box>
  );
};
