/* eslint-disable react/prop-types */
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Customizing the Accordion
const CustomizedAccordion = styled(Accordion)(({ theme, isfirst }) => ({
  backgroundColor: "#F8FAFD",
  borderRadius: isfirst ? "2rem 2rem 0 0" : "0.5rem",
  boxShadow: "none",
  border: "1px solid rgba(224, 224, 224, 0.5)",
  overflow: "hidden",
  paddingBottom: "0rem",
  marginBottom: "8px",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    borderRadius: isfirst ? "2rem" : "0.5rem",
    paddingLeft: theme.spacing(2),
  },
}));

const determineBookmarkColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 0, 0, 0.7)";
    case "High":
      return "rgb(255, 0, 0,0.01)";
    case "Medium":
      return "rgb(255, 140, 0, 0.1)";
    case "Low":
      return "rgba(16, 239, 65, 0.04)";
    case "Informational":
      return "rgb(144, 199, 254,0.1)";
    default:
      return "rgba(16, 239, 65, 0.1)";
  }
};

const determineBookmarBoarderkColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 0, 0)";
    case "High":
      return "rgb(255, 0, 0)";
    case "Medium":
      return "rgb(255, 165, 0)";
    case "Low":
      return "rgba(17, 150, 70, 1)";
    case "Informational":
      return "rgb(124, 179, 234)";
    default:
      return "rgba(16, 239, 65, 0.1)";
  }
};

const determineTextColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "rgb(255, 255, 255)";
    case "High":
      return "rgb(255, 0, 0)";
    case "Medium":
      return "rgb(255, 130, 0)";
    case "Low":
      return "rgba(17, 150, 70, 1)";
    case "Informational":
      return "rgb(104, 149, 194)";
    default:
      return "#000000";
  }
};

const Bookmark = styled("div")(({ theme, severity }) => ({
  backgroundColor: determineBookmarkColor(severity),
  color: determineTextColor(severity),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: "4px",
  fontSize: "0.8rem",
  fontWeight: "normal",
  marginLeft: theme.spacing(4),
  border: `1px solid ${determineBookmarBoarderkColor(severity)}`,
}));

const getSeverityColor = (severity) => {
  switch (severity) {
    case "Critical":
      return "#ff0000";
    case "High":
      return "#ff8c00";
    case "Medium":
      return "#ffa500";
    case "Low":
      return "#28a745";
    case "Informational":
      return "#28a745";
    default:
      return "#17a2b8";
  }
};

const SSLAccordion = ({ data, isfirst }) => {
  const { name, severity, description, cvss_score, ...details } = data;

  const cvssScore = parseFloat(cvss_score) || 0; // Convert CVSS score to a number
  const severityColor = getSeverityColor(severity);

  const chartData = [
    { name: "Severity", value: cvssScore },
    { name: "Remaining", value: 10 - cvssScore },
  ];

  return (
    <CustomizedAccordion isfirst={isfirst}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="px-8 mt-0 text-sm text-muted-foreground mb-0"
          sx={{ color: "#1B69AC" }}
          display="flex"
          alignItems="center"
          flexGrow={1}
        >
          {name}
        </Box>
        <Bookmark
          severity={severity}
          style={{
            width: "140px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {severity}
        </Bookmark>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "#F8FAFD" }}>
        {cvssScore ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="px-8"
          >
            {/* Description */}
            <Box className="text-xs text-muted-foreground" flex={1} mr={2}>
              {description}
            </Box>

            {/* Vertical Divider */}
            <Box
              sx={{
                width: "1px",
                backgroundColor: "#e0e0e0",
                height: "80px",
                mx: 2,
              }}
            ></Box>

            {/* CVSS Score Chart */}
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "150px" }}
            >
              <ResponsiveContainer width="100%" height={120}>
                <PieChart>
                  <Pie
                    data={chartData}
                    innerRadius="65%"
                    outerRadius="75%"
                    dataKey="value"
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={index === 0 ? severityColor : "#eeeeee"}
                      />
                    ))}
                    <Label
                      value={cvssScore.toFixed(1)}
                      position="center"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        fill: "black",
                      }}
                    />
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        ) : (
          <Box className="px-8 mt-0 text-xs text-muted-foreground">
            {description}
          </Box>
        )}
        {Object.entries(details).map(([key, value]) => (
          <React.Fragment key={key}>
            <Box className="px-8 mt-4 text-xs text-black">
              {key.replace(/_/g, " ").toUpperCase()}
            </Box>
            <Box className="px-8 mt-2 text-xs text-muted-foreground">
              {value}
            </Box>
          </React.Fragment>
        ))}
      </AccordionDetails>
    </CustomizedAccordion>
  );
};

export default SSLAccordion;
