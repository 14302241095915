/* eslint-disable react/prop-types */
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const VendorCard = ({ vendorDetails }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#1B2635",
        color: "white",
        marginBottom: "20px",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "8px" }}
        >
          Vendor: {vendorDetails.assignerShortName}
        </Typography>

        {/* Horizontal layout for CVE ID and Published Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Typography variant="body2">CVE ID: {vendorDetails.cveId}</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            Published Date:{" "}
            {new Date(vendorDetails.datePublished).toLocaleDateString()}
          </Typography>
        </Box>

        <Divider sx={{ backgroundColor: "#2E3B4E", margin: "16px 0" }} />

        <Typography variant="body2" sx={{ marginBottom: "8px" }}>
          Title: {vendorDetails.title}
        </Typography>

        {/* Big Accordion for the section below the title */}
        <Accordion
          sx={{
            backgroundColor: "#2A3D4D",
            color: "white",
            marginBottom: "16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls="big-accordion-content"
            id="big-accordion-header"
          >
            <Typography variant="body2">More Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Descriptions Accordion */}
            {vendorDetails.descriptions &&
              vendorDetails.descriptions.length > 0 && (
                <Accordion sx={{ backgroundColor: "#2A3D4D", color: "white" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="descriptions-content"
                    id="descriptions-header"
                  >
                    <Typography variant="body2">Descriptions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {vendorDetails.descriptions.map((desc, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={{ marginBottom: "4px", fontStyle: "italic" }}
                      >
                        {desc.value}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}

            {/* Affected Products Accordion */}
            {vendorDetails.affected && vendorDetails.affected.length > 0 && (
              <Accordion sx={{ backgroundColor: "#2A3D4D", color: "white" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  aria-controls="affected-content"
                  id="affected-header"
                >
                  <Typography variant="body2">Affected Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {vendorDetails.affected.map((product, index) => (
                    <Box key={index} sx={{ marginBottom: "8px" }}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Product: {product.product}
                      </Typography>
                      <Typography variant="body2">
                        Vendor: {product.vendor}
                      </Typography>
                      {product.versions &&
                        product.versions.map((version, idx) => (
                          <Typography
                            key={idx}
                            variant="body2"
                            sx={{ marginLeft: "16px" }}
                          >
                            Version: {version.version} ({version.status})
                          </Typography>
                        ))}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}

            {/* References Accordion */}
            {vendorDetails.references &&
              vendorDetails.references.length > 0 && (
                <Accordion sx={{ backgroundColor: "#2A3D4D", color: "white" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="references-content"
                    id="references-header"
                  >
                    <Typography variant="body2">References</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {vendorDetails.references.map((ref, index) => (
                      <Box key={index} sx={{ marginBottom: "8px" }}>
                        <Typography variant="body2">
                          <a
                            href={ref.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#1E88E5" }}
                          >
                            {ref.url}
                          </a>
                        </Typography>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default VendorCard;
