import * as Yup from "yup";

// Validation schema for name change form
export const nameChangeValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters")
    .matches(/^\S+$/, "First Name cannot contain spaces"),
  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters")
    .matches(/^\S+$/, "Last Name cannot contain spaces"),
});

// Validation schema for changing the user's password
export const passwordChangeValidationSchema = Yup.object({
  oldPassword: Yup.string().required("Old password is required."),
  newPassword: Yup.string()
    .required("New password is required.")
    .min(8, "Password must be at least 8 characters long.")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "New password must be different from the old password."
    ),
  confirmNewPassword: Yup.string()
    .required("Please confirm your new password.")
    .oneOf([Yup.ref("newPassword")], "Passwords do not match."),
});
