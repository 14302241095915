/* eslint-disable react/react-in-jsx-scope */
import { Box, Typography, Card, CardContent } from "@mui/material";
import PropTypes from "prop-types";

const CustomCard = ({ title, children }) => {
  return (
    <Card
      sx={{
        mb: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#14212d",
          color: "#fff",
          padding: 2,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            justifyContent: {
              xxs: "center",
              xs: "flex-start",
            },
          }}
        >
          {title}
        </Typography>
      </Box>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomCard;
