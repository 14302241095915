/* eslint-disable react/react-in-jsx-scope */
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import OTPVerificationForm from "./OTPVerificationForm";
import OTPSend from "./OTPSend";
import { Button, SecureNEX, Feedback } from "./index";

const EmailVerification = () => {
  const isVerified = useSelector((state) => state.auth.isVerified);

  return (
    <div className="flex h-screen">
      {/* Right side */}
      <div className="flex-1 hidden md:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img
              src={SecureNEX}
              alt="SecureNEX Logo"
              className="w-32 h-32 relative left-2"
            />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      {/* Left side */}
      <div className="flex-1 bg-white flex justify-center items-center">
        <div className="flex flex-col gap-2 w-80 md:w-96">
          <div className="text-2xl font-semibold text-center mb-4">
            Email Verification
          </div>
          {!isVerified ? (
            <div className="container p-5 shadow flex flex-col items-center">
              <OTPVerificationForm />
              <OTPSend />
              <Link to="/dashboard" className="text-black">
                <Button variant="outline" className="mt-5">
                  Go To Home
                </Button>
              </Link>
            </div>
          ) : (
            <div className="container p-5 shadow">
              <Feedback message="Email was verified!" />
              <div className="mt-4 w-100">
                <h6 className="text-center pb-2 w-100">Login to the Account</h6>
                <Link
                  to="/login"
                  className="btn btn-secondary btn-outline-primary text-light w-100"
                >
                  <span className="text-light">Login</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
