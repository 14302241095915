/* eslint-disable react/react-in-jsx-scope */
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

import {
  DashboardData,
  DashboardNoData,
  setScanMetrics,
  setSeverityCounts,
  axiosInstance,
  SCAN_SUMMARY_URL,
} from "./index";

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const { total_scans } = useSelector((state) => state.scanMetrics);

  const getMetricesData = async () => {
    try {
      const { data } = await axiosInstance.get(SCAN_SUMMARY_URL);
      dispatch(
        setSeverityCounts({
          critical: data?.data?.severity_counts?.critical || 0,
          high: data?.data?.severity_counts?.high || 0,
          medium: data?.data?.severity_counts?.medium || 0,
          low: data?.data?.severity_counts?.low || 0,
          info: data?.data?.severity_counts?.info || 0,
        })
      );

      dispatch(
        setScanMetrics({
          total_scans: data?.data?.scan_metrics?.total_scans || 0,
          mean_time: data?.data?.scan_metrics?.mean_time || 0,
          total_domains: data?.data?.scan_metrics?.total_domains || 0,
        })
      );
    } catch (error) {
      toast.error(
        "Failed to fetch summary data. Please try again later.",
        error
      );
    }
  };

  useEffect(() => {
    getMetricesData();
  }, []);

  return (
    <>
      {total_scans ? (
        // Otherwise, show the content
        <DashboardData />
      ) : (
        // Show No Data component if total_scans is zero
        <DashboardNoData />
      )}
    </>
  );
};
