/* eslint-disable react/react-in-jsx-scope */
import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  setLoading,
  setFeedback,
  setMessage,
  setVerifyLoading,
} from "../../redux/reducers/emailVerificationReducer";
import { setIsVerified } from "../../redux/reducers/authReducer";
import {
  Button,
  Input,
  Label,
  axiosInstance,
  email_verification,
} from "./index";

const OTPVerificationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);

  const [otpError, setOtpError] = useState("");

  const validationSchema = yup.object().shape({
    code: yup
      .string()
      .required("Please enter the OTP code.")
      .matches(/^\d{8}$/, "OTP code must be exactly 8 digits."),
  });

  const handleOTPVerification = async (values, { setSubmitting }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setVerifyLoading({ loading: true }));
    dispatch(setFeedback({ feedback: false }));
    setOtpError("");

    try {
      const response = await axiosInstance.post(
        email_verification,
        {
          passcode: values.code,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { is_verified, message } = response.data;

        if (is_verified) {
          dispatch(setVerifyLoading({ loading: false }));
          dispatch(setFeedback({ feedback: true }));
          dispatch(setMessage({ message }));
          dispatch(setIsVerified({ isVerified: is_verified }));
        } else {
          setOtpError(message);
          dispatch(setLoading({ loading: false }));
        }
      } else {
        dispatch(setLoading({ loading: false }));
        navigate("/error", {
          state: { from: location },
          replace: true,
        });
      }
    } catch (err) {
      setOtpError(err.response.data.error);
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
      dispatch(setMessage({ message: err.response.data.error }));
    } finally {
      dispatch(setVerifyLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: handleOTPVerification,
  });
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <div className="flex flex-col gap-4 items-start justify-center w-full mb-4">
      <div className="w-full">
        <Label htmlFor="code">Code</Label>
        <Input
          id={"code"}
          name={"code"}
          label="code"
          className="w-full"
          value={formik.values.code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
        />
      </div>
      {otpError && <div className="text-red-500">{otpError}</div>}
      {formik.touched.code && formik.errors.code && (
        <div className="text-red-500">{formik.errors.code}</div>
      )}
      <Button
        type="submit"
        onClick={() => formik?.handleSubmit()}
        className="w-full"
      >
        Verify
      </Button>
    </div>
  );
};

export default OTPVerificationForm;
